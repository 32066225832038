import React from 'react'
import { graphql } from 'gatsby'

import LayoutGeneral from '../components/Layouts'
import Hero from '../components/Hero'
import PostsList from '../components/PostsList'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import styled from 'styled-components'
import { media } from '../tokens'

const Wrapper = styled.main.attrs({
  role: 'main',
})`
  position: relative;
  border-radius: 5px;
  width: 80%;
  max-width: 770px;
  word-wrap: break-word;
  margin: 0px auto 30px auto;
  top: 30px;
  padding: 50px;
  box-shadow: 0 0 0 0, 0 6px 12px rgba(0, 0, 0, 0.1);
  color:black;

  @media ${media.maxLarge} {
    width: 100%;
    padding: 25px;
  }
`

class BlogList extends React.Component {
  render() {
    const { title, description } = this.props.data.site.siteMetadata
    const posts = this.props.data.posts.edges
    const { pageContext } = this.props

    return (
      <LayoutGeneral location={this.props.location}>
        <SEO 
          title={title}
          description={description}
          path="/News"
        />
        <Hero title={title} subTitle={description} />

        <Wrapper>
          <PostsList posts={posts} />
        </Wrapper>

        <Pagination
          nbPages={pageContext.nbPages}
          currentPage={pageContext.currentPage}
        />
      </LayoutGeneral>
    )
  }
}

export default BlogList

export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    posts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "//content/posts//" }
        frontmatter: { published: { ne: false }, unlisted: { ne: true } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields{
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            date
            slug
            language
            tags
            cover {
              publicURL
            }
            coverPublic
            imageShare {
              publicURL
            }
            translations {
              language
              link
            }
          }
        }
      }
    }
  }
`
